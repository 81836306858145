// /* eslint-disable */
import React from 'react';
import {
    Form,
    Input,
    Button,
    Col,
    Row,
    message,
    Select,
    Divider,
    InputNumber,
    Modal,
    Switch,
    Upload,
    DatePicker
} from 'antd';
import axios from "axios";
import Cookies from 'js-cookie';
import {RightOutlined, UploadOutlined} from "@ant-design/icons";
import moment from "moment/moment";
import backendApiLink from "../../../backendLinkConfig";
const { Option } = Select;


class AddCutPolish extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            referenceOptions: [],
            referenceGroupOptions: [],
            customerOptions: [],
            buyerOptions: [],
            sellerOptions: [],
            salesPersonOptions: [],
            partnerOptions: [],
            htByOptions: [],
            cpByOptions: [],
            preformerOptions: [],
            etByOptions: [],

            // fileList2: [],  // For the second photo uploader
            // previewVisible2: false,
            // previewImage2: '',
            // imgBBLink2: '',

            resultArray: [],
            photo: null,
            currentCode: this.props.currentCode,
            oldDeactivate: true,
            refGroup: false,

            fileList: {}, // Change fileList1 to fileList
            previewVisible: {}, // Change previewVisible1 to previewVisible
            imgBBLink: {}, // Change imgBBLink1 to imgBBLink


        };

        this.formRef = React.createRef();
    }

    async fetchCustomerOptions() {
        try {
            const response = await axios.post(`${backendApiLink}/getAllCustomers`);
            //console.log("response", response);

            // BuyerOptions Filter TYPE = Buyer
            const buyerOptions = response.data.result.filter((customer) => customer.TYPE === 'Buyer').map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }
            ));

            // SellerOptions Filter TYPE = Seller
            const sellerOptions = response.data.result.filter((customer) => customer.TYPE === 'Seller' || customer.TYPE === 'Partner').map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }
            ));

            // SalesPersonOptions Filter TYPE = Sales Person
            const salesPersonOptions = response.data.result.filter((customer) => customer.TYPE === 'Sales Person').map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }
            ));

            // PartnerOptions Filter TYPE = Partner
            const partnerOptions = response.data.result.filter((customer) => customer.TYPE === 'Partner').map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }
            ));

            // HTByOptions Filter TYPE = HT By
            const htByOptions = response.data.result.filter((customer) => customer.TYPE === 'Heat T').map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }
            ));

            // CPByOptions Filter TYPE = CP By
            const cpByOptions = response.data.result.filter((customer) => customer.TYPE === 'C&P').map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }
            ));

            // PreformerOptions Filter TYPE = Preformer
            const preformerOptions = response.data.result.filter((customer) => customer.TYPE === 'Preformer').map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }
            ));

            // ETByOptions Filter TYPE = Electric
            const etByOptions = response.data.result.filter((customer) => customer.TYPE === 'Electric').map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }
            ));

            this.setState({ buyerOptions, sellerOptions, salesPersonOptions, partnerOptions, htByOptions, cpByOptions, preformerOptions, etByOptions });

            return response.data.result.map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }));
        } catch (error) {
            console.error("Error fetching customer options:", error);
            return [];
        }
    }

    async componentDidMount() {
        try {
            const referenceOptions = await this.fetchReferenceOptions();
            this.setState({ referenceOptions });
            const customerOptions = await this.fetchCustomerOptions();
            this.setState({ customerOptions });
        } catch (error) {
            console.error('Error fetching reference options:', error);
        }
        if(this.props.refe){
            this.loadReferenceCPDetails(this.props.refe);
        }
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post(`${backendApiLink}/getItemsForReferenceInCP`);
            // console.log('response', response);
            const referenceGroupOptions = response.data.result.data1.map((item) => ({
                value: item.HT_ID,
                label: item.CODE + " - " + item.NAME
            }
            ));
            this.setState({ referenceGroupOptions });
            return response.data.result.data.map((item) => ({
                value: item.ITEM_ID_AI,
                    label: item.IS_IN_INVENTORY === 0 ? item.CODE + " - (Not in Inventory)" : item.CODE
            }
            ));
        } catch (error) {
            console.error('Error fetching reference options:', error);
            return [];
        }
    }



    loadReferenceCPDetails = async (value) => {
        const form = this.formRef.current;
        console.log("value", value);
        form.setFieldsValue({
            REFERENCE_ID_CP_1: value,
        });
    };

    loadReferenceItems = async (value) => {
        const form = this.formRef.current;
        console.log("value", value);

        try {
            const response = await axios.post(`${backendApiLink}/getReferenceFromCPGroup`, {
                HT_ID: value,
            });
            if (response.data.success) {
                console.log("response212", response);
                this.setState({ resultArray: response.data.result });
                // console.log("resultArray", this.state.resultArray);
            }
        } catch (error) {
            console.error("Error fetching reference options:", error);
            return [];
        }
    };

    handleFileChange = async ({ fileList }, { index }) => {
        try {
            this.setState({
                fileList: {
                    ...this.state.fileList,
                    [index]: fileList,
                },
                previewVisible: {
                    ...this.state.previewVisible,
                    [index]: false, // Close preview when a new file is selected
                },
                imgBBLink: {
                    ...this.state.imgBBLink,
                    [index]: '', // Reset imgBBLink when a new file is selected
                },
            });

            if (fileList.length > 0) {
                const imgFile = fileList[0].originFileObj;

                if (imgFile) {
                    const formData = new FormData();
                    formData.append('image', imgFile);

                    const response = await axios.post('https://api.imgbb.com/1/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        params: {
                            key: 'a94bb5679f1add2d50baee0220cc7926', // Replace with your ImgBB API key
                        },
                    });

                    const imgBBLinkKey = `imgBBLink${index}`;
                    this.setState({
                        [imgBBLinkKey]: response.data.data.url,
                    });
                    //show success message if response is success
                    if (response.data.success) {
                        console.log('Image uploaded to ImgBB:', response.data.data.url);
                        console.log('this.state', this.state);
                        message.success('Image uploaded successfully');
                    }
                    //show not success message if response is not success
                    else {
                        message.error('Failed to upload Image');
                    }
                    //console.log('this.state', this.state);
                    //console.log('Image uploaded to ImgBB:', response.data.data.url);
                }
            }
        } catch (error) {
            // Log and handle errors as needed
            console.error('Error in handleFileChange:', error);

            if (error.response) {
                console.error('ImgBB API Error:', error.response.data);
            } else if (error.request) {
                console.error('No response received from ImgBB API');
            } else {
                console.error('Error in request setup:', error.message);
            }
        }
    };


    handleSubmit = async (values) => {
        try {
            // Retrieve USER_ID from rememberedUser
            let rememberedUser = Cookies.get('rememberedUser');
            let USER_ID = null;

            if (rememberedUser) {
                rememberedUser = JSON.parse(rememberedUser);
                USER_ID = rememberedUser.USER_ID;
            }

            // Main data for the request
            const resultArrayData = {
                ...values,
                CREATED_BY: USER_ID,
                IS_GROUP: this.state.refGroup,
                PHOTO_1: this.state.imgBBLink1 || '',
            };

            const subDataArray = this.state.resultArray.map((referenceData, index) => ({
                REFERENCE_ID_CP: values[`REFERENCE_${index + 1}`],
                CP_TYPE: values[`CP_TYPE_${index + 1}`],
                WEIGHT_AFTER_CP: values[`WEIGHT_AFTER_CP_${index + 1}`],
                CP_COLOR: values[`CP_COLOR_${index + 1}`],
                SHAPE: values[`SHAPE_${index + 1}`],
                // CP_BY: values[`CP_BY_${index + 1}`],
                TOTAL_COST: values[`TOTAL_COST_${index + 1}`],
                PHOTO: this.state[`imgBBLink${index}`] || '',
                IS_REFERENCE_DEACTIVATED: values[`IS_REFERENCE_DEACTIVATED_${index + 1}`] ? 1 : 0,
            }));


            // Combine subDataArray in to resultArrayData
            resultArrayData.subDataArray = subDataArray;


            console.log('resultArrayData', resultArrayData);

            // Send the request
            const response = await axios.post(`${backendApiLink}/addCutPolish`, resultArrayData);

            if (response.data.success) {
                message.success('Cut & Polish added successfully');
                // Close the modal
                // wait for 10ms to close the modal

                // Refresh the table
                if(this.props.refe){
                    this.formRef.current.resetFields();
                }
                else{
                    this.props.refreshTable();
                    // You can reset the form if needed
                    this.formRef.current.resetFields();
                }
                this.props.onClose();
            } else {
                message.error('Failed to add Cut & Polish');
            }
        } catch (error) {
            console.error('Error adding Cut & Polish:', error);
            message.error('Internal server error');
        }
    };

    renderFormFields = () => {
        const { resultArray, referenceOptions, fileList1 } = this.state;

        const disableStyle = {
            pointerEvents: "none", // Disable pointer events to prevent interaction
            background: "#f5f5f5", // Set a background color to indicate it's disabled
            width: "100%"
        }

        const fileLimit = {
            accept: 'image/*', // Accept only image files
        };

        const inputStyle = {
            // pointerEvents: "none", // Disable pointer events to prevent interaction
            // background: "#f5f5f5", // Set a background color to indicate it's disabled
            width: "100%"
        }


        //if refGroup = false show Only one reference
        if (!this.state.refGroup) {
return (
                <div>
                    <Divider/>
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name="REFERENCE_ID_CP_1" label="Reference" rules={[{ required: true, message: 'Please select Reference' }]}>
                                <Select
                                    placeholder="Select Reference"
                                    allowClear
                                    style={this.props.refe ? disableStyle : {}}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                        (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                    }>
                                    {referenceOptions.map((option) => (
                                        <Option key={option.value} value={option.value} title={option.label}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name="CP_TYPE_1" label="Cut and Polished Type" rules={[{ required: true, message: 'Please select Cut and Polished Type' }]}>
                                <Select placeholder="Select Cut and Polished Type" showSearch>
                                    <Option value="Blue Sapphire Natural">Blue Sapphire - Natural</Option>
                                    <Option value="Blue Sapphire Heated">Blue Sapphire - Heated</Option>
                                    <Option value="Yellow Sapphire">Yellow Sapphire</Option>
                                    <Option value="Pink Sapphire Natural">Pink Sapphire - Natural</Option>
                                    <Option value="Pink Sapphire Treated">Pink Sapphire - Treated</Option>
                                    <Option value="Purple Sapphire Natural">Purple Sapphire - Natural</Option>
                                    <Option value="Violet Sapphire Natural">Violet Sapphire - Natural</Option>
                                    <Option value="Blue Sapphire Treated Lots">Blue Sapphire - Treated Lots</Option>
                                    <Option value="Padparadscha Sapphire Natural">Padparadscha Sapphire - Natural</Option>
                                    <Option value="Yellow Sapphire Heated">Yellow Sapphire Heated</Option>
                                    <Option value="Spinel">Spinel</Option>
                                    <Option value="Alexandrite">Alexandrite</Option>
                                    <Option value="Chrysoberyl">Chrysoberyl</Option>
                                    <Option value="Cats Eye">Cat's Eye</Option>
                                    <Option value="Orange Sapphire Natural">Orange Sapphire - Natural</Option>
                                    <Option value="Orange Sapphire Heated">Orange Sapphire - Heated</Option>
                                    <Option value="Pink Sapphire Heated">Pink Sapphire - Heated</Option>
                                    <Option value="Purple Sapphire Heated">Purple Sapphire - Heated</Option>
<Option value="Blue Star Sapphire">Blue Star Sapphire</Option>
<Option value="Pink Star Sapphire">Pink Star Sapphire</Option>
<Option value="Purple Star Sapphire">Purple Star Sapphire</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name="WEIGHT_AFTER_CP_1" label="Weight CP" >
                                <InputNumber min={0} step={0.01} placeholder="Enter Weight" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name="CP_COLOR_1" label="Color" >
                                <Input placeholder="Enter Color" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name="SHAPE_1" label="Shape">
                                <Select placeholder="Select Shape" allowClear showSearch>
                                    <Option value="Oval">Oval</Option>
                                    <Option value="Cabochon Oval">Cabochon Oval</Option>
                                    <Option value="Cushion">Cushion</Option>
                                    <Option value="Heart">Heart</Option>
                                    <Option value="Pear">Pear</Option>
                                    <Option value="Radiant">Radiant</Option>
                                    <Option value="Rectangle">Rectangle</Option>
                                    <Option value="Round">Round</Option>
                                    <Option value="Square">Square</Option>
                                    <Option value="Sugarloaf">Sugarloaf</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name={`TOTAL_COST_1`}
                                label="Total Cost To Item"
                                type="number"
                            >
                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Total Cost" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={3}>
                            {/* File Upload */}
                            <Form.Item
                                name={`PHOTO_1`}
                                label="Upload Photo"
                            >
                                <Upload
                                    customRequest={({ onSuccess, onError, file }) => {
                                        onSuccess();
                                    }
                                    }
                                    fileList={this.state.fileList[1] || []}
                                    onChange={(info) => this.handleFileChange(info, { index: 1 })}
                                    {...fileLimit}
                                    listType="picture-card"
                                    showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
                                    maxCount={1} // Allow only one file
                                    onPreview={() => this.setState({ previewVisible1: true })}
                                >
                                    {this.state.fileList[1]?.length >= 1 ? null : (
                                        <div>
                                            <UploadOutlined />
                                            <div className="ant-upload-text">Upload</div>
                                        </div>
                                    )}
                                </Upload>
                                {/* Display uploaded image */}
                                <div className="clearfix">
                                    <Modal
                                        visible={this.state.previewVisible1}
                                        footer={null}
                                        onCancel={() => this.setState({ previewVisible1: false })}
                                    >
                                        {this.state.imgBBLink1 === '' ? (
                                            <div className="loading-indicator">Uploading...</div>
                                        ) : (
                                            <img
                                                alt="Preview"
                                                style={{ width: '100%' }}
                                                src={this.state.imgBBLink1}
                                                onError={(e) => {
                                                    console.error('Image loading error:', e);
                                                }
                                                }
                                            />
                                        )}
                                    </Modal>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name="IS_REFERENCE_DEACTIVATED_1"
                                label="Deactivate Reference"
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Switch
                                    checkedChildren="Remove Reference"
                                    unCheckedChildren="Keep Reference"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            );
        }
        //if refGroup = true show multiple references
        else {
            console.log("resultArray123123", resultArray);


            return resultArray.map((referenceData, index) => (
                <div key={index}>
                    <Divider/>
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name={`REFERENCE_${index + 1}`} label={`Reference ${index + 1}`}
                                       initialValue={referenceData.ITEM_ID_AI}>
                                <Select
                                    placeholder="Select Reference"
                                    allowClear
                                    style={disableStyle}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                        (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                    }>
                                    {referenceOptions.map((option) => (
                                        <Option key={option.value} value={option.value} title={option.label}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name={`CP_TYPE_${index + 1}`} label="Cut and Polished Type" rules={[{ required: true, message: 'Please select Cut and Polished Type' }]}>
                                <Select placeholder="Select Cut and Polished Type" showSearch>
                                    <Option value="Blue Sapphire Natural">Blue Sapphire - Natural</Option>
                                    <Option value="Blue Sapphire Heated">Blue Sapphire - Heated</Option>
                                    <Option value="Yellow Sapphire">Yellow Sapphire</Option>
                                    <Option value="Pink Sapphire Natural">Pink Sapphire - Natural</Option>
                                    <Option value="Pink Sapphire Treated">Pink Sapphire - Treated</Option>
                                    <Option value="Purple Sapphire Natural">Purple Sapphire - Natural</Option>
                                    <Option value="Violet Sapphire Natural">Violet Sapphire - Natural</Option>
                                    <Option value="Blue Sapphire Treated Lots">Blue Sapphire - Treated Lots</Option>
                                    <Option value="Padparadscha Sapphire Natural">Padparadscha Sapphire -
                                        Natural</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name={`WEIGHT_AFTER_CP_${index + 1}`} label="Weight CP">
                                <InputNumber min={0} step={0.01} placeholder="Enter Weight" style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name={`CP_COLOR_${index + 1}`}
                                       label="Color"
                            >
                                <Input  placeholder="Enter Color"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name={`SHAPE_${index + 1}`} label="Shape">
                                <Select placeholder="Select Shape" allowClear showSearch>
                                    <Option value="Oval">Oval</Option>
                                    <Option value="Cabochon Oval">Cabochon Oval</Option>
                                    <Option value="Cushion">Cushion</Option>
                                    <Option value="Heart">Heart</Option>
                                    <Option value="Pear">Pear</Option>
                                    <Option value="Radiant">Radiant</Option>
                                    <Option value="Rectangle">Rectangle</Option>
                                    <Option value="Round">Round</Option>
                                    <Option value="Square">Square</Option>
                                    <Option value="Sugarloaf">Sugarloaf</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name={`TOTAL_COST_${index + 1}`}
                                label={`Total Cost To Item ${index + 1}`}
                                type="number"
                            >
                                <InputNumber style={inputStyle} min={0} step={0.01} placeholder="Enter Total Cost" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={3}>
                            {/* File Upload */}
                            <Form.Item
                                name={`PHOTO_${index + 1}`}
                                label={`Upload Photo ${index + 1}`}
                            >
                                <Upload
                                    customRequest={({ onSuccess, onError, file }) => {
                                        onSuccess();
                                    }}
                                    fileList={this.state.fileList[index] || []}
                                    onChange={(info) => this.handleFileChange(info, { index })}
                                    {...fileLimit}
                                    listType="picture-card"
                                    showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
                                    maxCount={1} // Allow only one file
                                    onPreview={() => this.setState({ [`previewVisible${index}`]: true })}
                                >
                                    {this.state.fileList[index]?.length >= 1 ? null : (
                                        <div>
                                            <UploadOutlined />
                                            <div className="ant-upload-text">Upload</div>
                                        </div>
                                    )}
                                </Upload>
                                {/* Display uploaded image */}
                                <div className="clearfix">
                                    <Modal
                                        visible={this.state[`previewVisible${index}`]}
                                        footer={null}
                                        onCancel={() => this.setState({ [`previewVisible${index}`]: false })}
                                    >
                                        {this.state[`imgBBLink${index}`] === '' ? (
                                            <div className="loading-indicator">Uploading...</div>
                                        ) : (
                                            <img
                                                alt="Preview"
                                                style={{ width: '100%' }}
                                                src={this.state[`imgBBLink${index}`]}
                                                onError={(e) => {
                                                    console.error('Image loading error:', e);
                                                }}
                                            />
                                        )}
                                    </Modal>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name={`IS_REFERENCE_DEACTIVATED_${index + 1}`}
                                label="Deactivate Reference"
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Switch
                                    checkedChildren="Remove Reference"
                                    unCheckedChildren="Keep Reference"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            ));
        }

    }

    handleItemGroupChange = (checked) => {
        if (checked) {
            this.setState({ refGroup: true });

        } else {
            this.setState({ refGroup: false });
        }
    }


    render() {
        const { referenceOptions,referenceGroupOptions,fileList2 } = this.state;



        const fileLimit = {
            accept: 'image/*', // Accept only image files
        };

        return (
            <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                <Row gutter={[16, 16]} justify="left" align="top">
                    {this.props.refe === undefined ? (
                    <Col xs={24} sm={24} md={24} lg={6}>
                        <Form.Item
                            name="IS_USE_REFERENCE_GROUP"
                            label="Use CP Group as Reference"
                            initialValue={this.state.refGroup}
                        >
                            <Switch
                                checkedChildren="Use Reference Group"
                                unCheckedChildren="Use Reference Item"
                                onChange={(checked) => {
                                    this.handleItemGroupChange(checked);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    ) : null}
                    <Col xs={24} sm={12} md={12} lg={6}>
                        {/* Date */}
                        <Form.Item
                            name="DATE"
                            label="Date"
                            rules={[{ required: true, message: 'Please select Date' }]}  // Set the default date to today
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6}>
                        <Form.Item name="CP_BY" label="Cutting & Polished By" rules={[{ required: true, message: 'Please select Cutting & Polished By' }]}>
                            <Select placeholder="Select Customer" allowClear showSearch
                                    filterOption={(input, option) =>
                                        (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                        (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                    }>
                                {this.state.cpByOptions.map((option) => (
                                    <Option key={option.value} value={option.value} title={option.label}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {this.state.refGroup ? (
                            <Col xs={24} sm={12} md={12} lg={6}>
                                <Form.Item
                                    name="REFERENCE_ID_CP_GROUP"
                                    label="Reference Group"
                                    rules={[{ required: true, message: 'Please select Reference Group' }]}
                                >
                                    <Select
                                        placeholder="Select Reference Group"
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                            (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                        }
                                        onChange={this.loadReferenceItems}
                                    >
                                        {referenceGroupOptions.map((option) => (
                                            <Option key={option.value} value={option.value} title={option.label}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : null}

                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="REMARK"
                            label="Remarks"
                        >
                            <Input.TextArea rows={2} placeholder="Enter remarks" />
                        </Form.Item>
                    </Col>

                    </Row>
                  {this.renderFormFields()}
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Add Cut and Polish
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default AddCutPolish;
