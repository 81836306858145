/* eslint-disable */

import React, { Component } from 'react';
import {
    Card,
    Row,
    Col,
    Form,
    InputNumber,
    Select,
    Switch,
    Upload,
    Button,
    message,
    DatePicker,
    Input, Divider, Modal
} from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import moment from 'moment';
import {RightOutlined, UploadOutlined} from "@ant-design/icons";
import backendApiLink from "../../../backendLinkConfig";

const { Option } = Select;

class UpdateCutPolish extends Component {
    constructor(props) {
        super(props);

        this.state = {
            referenceOptions: [],
            referenceGroupOptions: [],
            customerOptions: [],
            buyerOptions: [],
            sellerOptions: [],
            salesPersonOptions: [],
            partnerOptions: [],
            htByOptions: [],
            cpByOptions: [],
            preformerOptions: [],
            etByOptions: [],

            // fileList2: [],  // For the second photo uploader
            // previewVisible2: false,
            // previewImage2: '',
            // imgBBLink2: '',

            resultArray: [],
            photo: null,
            currentCode: null,

            fileList: {}, // Change fileList1 to fileList
            previewVisible: {}, // Change previewVisible1 to previewVisible
            imgBBLink: {}, // Change imgBBLink1 to imgBBLink


        };

        this.formRef = React.createRef();
        this.loadReferenceItems(this.props.initialValues.OLD_REFERENCE);

    }

    async fetchCustomerOptions() {
        try {
            const response = await axios.post(`${backendApiLink}/getAllCustomers`);
            //console.log("response", response);

            // BuyerOptions Filter TYPE = Buyer
            const buyerOptions = response.data.result.filter((customer) => customer.TYPE === 'Buyer').map((customer) => ({
                    value: customer.CUSTOMER_ID,
                    label: customer.NAME,
                }
            ));

            // SellerOptions Filter TYPE = Seller
            const sellerOptions = response.data.result.filter((customer) => customer.TYPE === 'Seller' || customer.TYPE === 'Partner').map((customer) => ({
                    value: customer.CUSTOMER_ID,
                    label: customer.NAME,
                }
            ));

            // SalesPersonOptions Filter TYPE = Sales Person
            const salesPersonOptions = response.data.result.filter((customer) => customer.TYPE === 'Sales Person').map((customer) => ({
                    value: customer.CUSTOMER_ID,
                    label: customer.NAME,
                }
            ));

            // PartnerOptions Filter TYPE = Partner
            const partnerOptions = response.data.result.filter((customer) => customer.TYPE === 'Partner').map((customer) => ({
                    value: customer.CUSTOMER_ID,
                    label: customer.NAME,
                }
            ));

            // HTByOptions Filter TYPE = HT By
            const htByOptions = response.data.result.filter((customer) => customer.TYPE === 'Heat T').map((customer) => ({
                    value: customer.CUSTOMER_ID,
                    label: customer.NAME,
                }
            ));

            // CPByOptions Filter TYPE = CP By
            const cpByOptions = response.data.result.filter((customer) => customer.TYPE === 'C&P').map((customer) => ({
                    value: customer.CUSTOMER_ID,
                    label: customer.NAME,
                }
            ));

            // PreformerOptions Filter TYPE = Preformer
            const preformerOptions = response.data.result.filter((customer) => customer.TYPE === 'Preformer').map((customer) => ({
                    value: customer.CUSTOMER_ID,
                    label: customer.NAME,
                }
            ));

            const etByOptions = response.data.result.filter((customer) => customer.TYPE === 'Electric').map((customer) => ({
                    value: customer.CUSTOMER_ID,
                    label: customer.NAME,
                }
            ));
            this.setState({ buyerOptions, sellerOptions, salesPersonOptions, partnerOptions, htByOptions, cpByOptions, preformerOptions, etByOptions });

            return response.data.result.map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }));
        } catch (error) {
            console.error("Error fetching customer options:", error);
            return [];
        }
    }

    async componentDidMount() {
        try {
            const referenceOptions = await this.fetchReferenceOptions();
            this.setState({ referenceOptions });
            const customerOptions = await this.fetchCustomerOptions();
            this.setState({ customerOptions });
        } catch (error) {
            console.error('Error fetching reference options:', error);
        }
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post(`${backendApiLink}/getItemsForReferenceInCP`);
            // console.log('response', response);
            const referenceGroupOptions = response.data.result.data1.map((item) => ({
                    value: item.HT_ID,
                    label: item.CODE + " - " + item.NAME
                }
            ));
            this.setState({ referenceGroupOptions });
            return response.data.result.data.map((item) => ({
                    value: item.ITEM_ID_AI,
                    label: item.IS_IN_INVENTORY === 0 ? item.CODE + " - (Not in Inventory)" : item.CODE
                }
            ));
        } catch (error) {
            console.error('Error fetching reference options:', error);
            return [];
        }
    }



    // loadReferenceCPDetails = async (value) => {
    //     const form = this.formRef.current;
    //     console.log("this.props", this.props.initialValues);
    //     // form.resetFields(['CODE_AFTER_CUTTING']);
    //     try {
    //         this.setState({
    //             imgBBLink2: '',
    //             fileList2: [],
    //         });
    //         const response = await axios.post(`${backendApiLink}/getReferenceCPDetails`, {
    //             ITEM_ID_AI: value,
    //         });
    //         if (response.data.success) {
    //             //console.log("response1", response);
    //             form.setFieldsValue({ CP_TYPE: response.data.result[0].CP_TYPE });
    //             form.setFieldsValue({ CP_COLOR: response.data.result[0].CP_COLOR });
    //             form.setFieldsValue({ SHAPE: response.data.result[0].SHAPE });
    //             form.setFieldsValue({ CP_BY: response.data.result[0].CP_BY });
    //             form.setFieldsValue({ TOTAL_COST: response.data.result[0].TOTAL_COST });
    //             form.setFieldsValue({ REMARK: response.data.result[0].REMARK ? response.data.result[0].REMARK : '' });
    //             form.setFieldsValue({ WEIGHT_AFTER_CP: response.data.result[0].WEIGHT_AFTER_CP });
    //             if(response.data.result[0].PHOTO){
    //                 this.setState({ imgBBLink2: response.data.result[0].PHOTO });
    //                 this.setState({ fileList2: [{
    //                         uid: '-1',
    //                         name: 'image.png',
    //                         status: 'done',
    //                         url: response.data.result[0].PHOTO,
    //                     }] });
    //             }
    //             this.setState({ currentCode: response.data.result[0].ITEM_CODE });
    //         } else {
    //             message.error('Failed to fetch Item Details');
    //         }
    //     } catch (error) {
    //         console.error("Error fetching reference options:", error);
    //         return [];
    //     }
    // };


    handleFileChange = async ({ fileList }, { index }) => {
        try {
            this.setState({
                fileList: {
                    ...this.state.fileList,
                    [index]: fileList,
                },
                previewVisible: {
                    ...this.state.previewVisible,
                    [index]: false, // Close preview when a new file is selected
                },
                imgBBLink: {
                    ...this.state.imgBBLink,
                    [index]: '', // Reset imgBBLink when a new file is selected
                },
            });

            if (fileList.length > 0) {
                const imgFile = fileList[0].originFileObj;

                if (imgFile) {
                    const formData = new FormData();
                    formData.append('image', imgFile);

                    const response = await axios.post('https://api.imgbb.com/1/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        params: {
                            key: 'a94bb5679f1add2d50baee0220cc7926', // Replace with your ImgBB API key
                        },
                    });

                    const imgBBLinkKey = `imgBBLink${index}`;
                    this.setState({
                        [imgBBLinkKey]: response.data.data.url,
                    });
                    //show success message if response is success
                    if (response.data.success) {
                        console.log('Image uploaded to ImgBB:', response.data.data.url);
                        console.log('this.state', this.state);
                        message.success('Image uploaded successfully');
                    }
                    //show not success message if response is not success
                    else {
                        message.error('Failed to upload Image');
                    }
                    //console.log('this.state', this.state);
                    //console.log('Image uploaded to ImgBB:', response.data.data.url);
                }
            }
        } catch (error) {
            // Log and handle errors as needed
            console.error('Error in handleFileChange:', error);

            if (error.response) {
                console.error('ImgBB API Error:', error.response.data);
            } else if (error.request) {
                console.error('No response received from ImgBB API');
            } else {
                console.error('Error in request setup:', error.message);
            }
        }
    };

    loadReferenceItems = async (value) => {
        const form = this.formRef.current;
        console.log("value", value);
            try {
                const response = await axios.post(`${backendApiLink}/getReferenceFromCPGroupUpdate`, {
                    OLD_REFERENCE: value,
                    IS_GROUP: this.props.initialValues.IS_GROUP,
                });
                if (response.data.success) {
                    // console.log("response.data.result", response.data.result);
                    if(this.props.initialValues.IS_GROUP === 1){

                        this.setState({resultArray: response.data.result});
                        //set Photo
                        for (let i = 0; i < response.data.result.length; i++) {
                            if(response.data.result[i].PHOTO){
                                this.setState({ [`imgBBLink${i}`]: response.data.result[i].PHOTO });
                                this.setState({ [`fileList${i}`]: [{
                                        uid: '-1',
                                        name: 'image.png',
                                        status: 'done',
                                        url: response.data.result[i].PHOTO,
                                    }] });
                            }
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching reference options:", error);
                return [];
            }
    };

    handleMarkAsFinished = async () => {
        try {
            const response = await axios.post(`${backendApiLink}/markAsFinishedCutPolish`, {
                CP_ID: this.props.initialValues.CP_ID,
                // REFERENCE: this.props.initialValues.REFERENCE,
                // OLD_REFERENCE: this.props.initialValues.OLD_REFERENCE,
                // IS_GROUP: this.props.initialValues.IS_GROUP,
            });

            if (response.data.success) {
                message.success('Cut & Polish Marked as Finished successfully');
                // Close the modal
                this.props.onUpdate();
                this.props.onCancel();
                // You can reset the form if needed
                // this.formRef.current.resetFields();
            } else {
                message.error('Failed to Mark as Finished Cut & Polish');
            }
        } catch (error) {
            console.error('Error Marking as Finished Cut & Polish:', error);
            message.error('Internal server error');
        }
    }


    handleSubmit = async (values) => {
        try {

            // Main data for the request
            const resultArrayData = {
                ...values,
                IS_GROUP: this.props.initialValues.IS_GROUP,
                PHOTO_1: this.state.imgBBLink1 || this.props.initialValues.PHOTO_LINK,
                CP_ID: this.props.initialValues.CP_ID,
                REFERENCE: this.props.initialValues.REFERENCE,
            };

            const subDataArray = this.state.resultArray.map((referenceData, index) => ({
                REFERENCE_ID_CP: values[`REFERENCE_${index + 1}`],
                CP_TYPE: values[`CP_TYPE_${index + 1}`],
                WEIGHT_AFTER_CP: values[`WEIGHT_AFTER_CP_${index + 1}`],
                CP_COLOR: values[`CP_COLOR_${index + 1}`],
                SHAPE: values[`SHAPE_${index + 1}`],
                NEW_REF: values[`NEW_REF_${index + 1}`],
                // CP_BY: values[`CP_BY_${index + 1}`],
                TOTAL_COST: values[`TOTAL_COST_${index + 1}`],
                PHOTO: this.state[`imgBBLink${index}`] || this.state.resultArray[index].PHOTO,
            }));


            // Combine subDataArray in to resultArrayData
            resultArrayData.subDataArray = subDataArray;


            console.log('resultArrayData', resultArrayData);

            // Send the request
            const response = await axios.post(`${backendApiLink}/updateCutPolish`, resultArrayData);

            if (response.data.success) {
                message.success('Cut & Polish update successfully');
                // Close the modal
                this.props.onUpdate();
                this.props.onCancel();
                // You can reset the form if needed
                // this.formRef.current.resetFields();
            } else {
                message.error('Failed to update Cut & Polish');
            }
        } catch (error) {
            console.error('Error updating Cut & Polish:', error);
            message.error('Internal server error');
        }
    };

    handleApprove = async () => {
        try {
            const sendObject = {
                CP_ID: this.props.initialValues.CP_ID,
                REFERENCE: this.props.initialValues.REFERENCE,
                OLD_REFERENCE: this.props.initialValues.OLD_REFERENCE,
                IS_GROUP: this.props.initialValues.IS_GROUP,
            }

            // Send the request
            const response = await axios.post(`${backendApiLink}/approveCutPolish`, sendObject);

            if (response.data.success) {
                message.success('Cut & Polish Approved successfully');
                // Close the modal
                this.props.onUpdate();
                this.props.onCancel();
                // You can reset the form if needed
                // this.formRef.current.resetFields();
            } else {
                message.error('Failed to Approve Cut & Polish');
            }
        } catch (error) {
            console.error('Error Approving Cut & Polish:', error);
            message.error('Internal server error');
        }
    };

    renderFormFields = () => {
        const { resultArray, referenceOptions, fileList1 } = this.state;
        const { type } = this.props;

        const disableStyle = {
            pointerEvents: "none", // Disable pointer events to prevent interaction
            background: "#f5f5f5", // Set a background color to indicate it's disabled
            width: "100%"
        }

        const fileLimit = {
            accept: 'image/*', // Accept only image files
        };

        const inputStyle = {
            // pointerEvents: "none", // Disable pointer events to prevent interaction
            // background: "#f5f5f5", // Set a background color to indicate it's disabled
            width: "100%"
        }


        //if refGroup = false show Only one reference
        if (!this.props.initialValues.IS_GROUP) {
            return (
                <div>
                    <Divider/>
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name="REFERENCE_ID_CP_1" label="Old Reference" rules={[{ required: true, message: 'Please select Reference' }]} initialValue={this.props.initialValues.OLD_REFERENCE}>
                                <Select
                                    placeholder="Select Reference"
                                    allowClear
                                    style={disableStyle}
                                >
                                    {referenceOptions.map((option) => (
                                        <Option key={option.value} value={option.value} title={option.label}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name="CP_TYPE_1" label="Cut and Polished Type" rules={[{ required: true, message: 'Please select Cut and Polished Type' }]} initialValue={this.props.initialValues.CP_TYPE}>
                                <Select placeholder="Select Cut and Polished Type" showSearch style={disableStyle}>
                                    <Option value="Blue Sapphire Natural">Blue Sapphire - Natural</Option>
                                    <Option value="Blue Sapphire Heated">Blue Sapphire - Heated</Option>
                                    <Option value="Yellow Sapphire">Yellow Sapphire</Option>
                                    <Option value="Pink Sapphire Natural">Pink Sapphire - Natural</Option>
                                    <Option value="Pink Sapphire Treated">Pink Sapphire - Treated</Option>
                                    <Option value="Purple Sapphire Natural">Purple Sapphire - Natural</Option>
                                    <Option value="Violet Sapphire Natural">Violet Sapphire - Natural</Option>
                                    <Option value="Blue Sapphire Treated Lots">Blue Sapphire - Treated Lots</Option>
                                    <Option value="Padparadscha Sapphire Natural">Padparadscha Sapphire - Natural</Option>
                                    <Option value="Yellow Sapphire Heated">Yellow Sapphire Heated</Option>
                                    <Option value="Spinel">Spinel</Option>
                                    <Option value="Alexandrite">Alexandrite</Option>
                                    <Option value="Chrysoberyl">Chrysoberyl</Option>
                                    <Option value="Cats Eye">Cat's Eye</Option>
                                    <Option value="Orange Sapphire Natural">Orange Sapphire - Natural</Option>
                                    <Option value="Orange Sapphire Heated">Orange Sapphire - Heated</Option>
                                    <Option value="Pink Sapphire Heated">Pink Sapphire - Heated</Option>
                                    <Option value="Purple Sapphire Heated">Purple Sapphire - Heated</Option>
<Option value="Blue Star Sapphire">Blue Star Sapphire</Option>
<Option value="Pink Star Sapphire">Pink Star Sapphire</Option>
<Option value="Purple Star Sapphire">Purple Star Sapphire</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name="NEW_REF_1" label="New Reference" rules={[{ required: true, message: 'Please select Reference' }]} initialValue={parseInt(this.props.initialValues.REFERENCE)}>
                                <Select
                                    placeholder="Select Reference"
                                    allowClear
                                    style={disableStyle}
                                >
                                    {referenceOptions.map((option) => (
                                        <Option key={option.value} value={option.value} title={option.label}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name="WEIGHT_AFTER_CP_1" label="Weight CP" initialValue={this.props.initialValues.WEIGHT_AFTER_CP} style={{ type: "number" }}>
                                <InputNumber min={0} step={0.01} placeholder="Enter Weight" style={ type === 'view' ? disableStyle : {width: '100%'}}/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name="CP_COLOR_1" label="Color" initialValue={this.props.initialValues.CP_COLOR}>
                                <Input placeholder="Enter Color" style={ type === 'view' || type === 'cp_user_edit' ? disableStyle : {width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name="SHAPE_1" label="Shape" initialValue={this.props.initialValues.SHAPE}>
                                <Select placeholder="Select Shape" allowClear showSearch style={ type === 'view' ? disableStyle : {width: '100%'}}>
                                    <Option value="Oval">Oval</Option>
                                    <Option value="Cabochon Oval">Cabochon Oval</Option>
                                    <Option value="Cushion">Cushion</Option>
                                    <Option value="Heart">Heart</Option>
                                    <Option value="Pear">Pear</Option>
                                    <Option value="Radiant">Radiant</Option>
                                    <Option value="Rectangle">Rectangle</Option>
                                    <Option value="Round">Round</Option>
                                    <Option value="Square">Square</Option>
                                    <Option value="Sugarloaf">Sugarloaf</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {type !== 'cp_user_edit' ? (
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name={`TOTAL_COST_1`}
                                label="Total Cost To Item"
                                type="number"
                                initialValue={this.props.initialValues.TOTAL_COST}
                            >
                                <InputNumber min={0} step={0.01} placeholder="Enter Total Cost" style={ type === 'view' ? disableStyle : {width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        ) : null}
                        {this.props.initialValues.PHOTO_LINK
                            ? (
                            <Col span={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Item label="Photo">
                                    <img
                                        alt="Initial Photo"
                                        style={{ width: '50%', borderRadius: '5px', cursor: 'pointer' }}
                                        key={`img${0}`}
                                        src={this.props.initialValues.PHOTO_LINK
                                        }
                                        onClick={() => {
                                            this.setState({ enlargedImageVisibleHT: 0 }); // Use index as the unique key
                                        }}
                                    />

                                    {/* Enlarged view modal */}
                                    <Modal
                                        visible={this.state.enlargedImageVisibleHT === 0}
                                        footer={null}
                                        onCancel={() => this.setState({ enlargedImageVisibleHT: null })} // Reset to null when modal is closed
                                        key={`modal${0}`} // Use index as the unique key for the modal
                                    >
                                        <img
                                            alt="Enlarged View"
                                            style={{ width: '100%' }}
                                            key={`img${0}`}
                                            src={this.props.initialValues.PHOTO_LINK
                                            }
                                            onError={(e) => {
                                                console.error('Image loading error:', e);
                                            }}
                                        />
                                    </Modal>
                                </Form.Item>
                            </Col>

                        ) : null}
                        {type === 'edit' ? (
                        <Col xs={24} sm={24} md={24} lg={3}>
                            {/* File Upload */}
                            <Form.Item
                                name={`PHOTO_1`}
                                label="Upload Photo"
                            >
                                <Upload
                                    customRequest={({ onSuccess, onError, file }) => {
                                        onSuccess();
                                    }
                                    }
                                    fileList={this.state.fileList[1] || []}
                                    onChange={(info) => this.handleFileChange(info, { index: 1 })}
                                    {...fileLimit}
                                    listType="picture-card"
                                    showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
                                    maxCount={1} // Allow only one file
                                    onPreview={() => this.setState({ previewVisible1: true })}
                                >
                                    {this.state.fileList[1]?.length >= 1 ? null : (
                                        <div>
                                            <UploadOutlined />
                                            <div className="ant-upload-text">Upload</div>
                                        </div>
                                    )}
                                </Upload>
                                {/* Display uploaded image */}
                                <div className="clearfix">
                                    <Modal
                                        visible={this.state.previewVisible1}
                                        footer={null}
                                        onCancel={() => this.setState({ previewVisible1: false })}
                                    >
                                        {this.state.imgBBLink1 === '' ? (
                                            <div className="loading-indicator">Uploading...</div>
                                        ) : (
                                            <img
                                                alt="Preview"
                                                style={{ width: '100%' }}
                                                src={this.state.imgBBLink1}
                                                onError={(e) => {
                                                    console.error('Image loading error:', e);
                                                }
                                                }
                                            />
                                        )}
                                    </Modal>
                                </div>
                            </Form.Item>
                        </Col>
                        ) : null}
                    </Row>
                </div>
            );
        }
        //if refGroup = true show multiple references
        else {
            // console.log("resultArray123123", resultArray);


            return resultArray.map((referenceData, index) => (
                <div key={index}>
                    <Divider/>
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name={`REFERENCE_${index + 1}`} label={`Old Reference ${index + 1}`}
                                       initialValue={referenceData.REFERENCE_ID_CP}>
                                <Select
                                    placeholder="Select Reference"
                                    allowClear
                                    style={disableStyle}
                                >
                                    {referenceOptions.map((option) => (
                                        <Option key={option.value} value={option.value} title={option.label}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name={`CP_TYPE_${index + 1}`} label="Cut and Polished Type" rules={[{ required: true, message: 'Please select Cut and Polished Type' }]} initialValue={referenceData.CP_TYPE}>
                                <Select placeholder="Select Cut and Polished Type" showSearch style={disableStyle}>
                                    <Option value="Blue Sapphire Natural">Blue Sapphire - Natural</Option>
                                    <Option value="Blue Sapphire Heated">Blue Sapphire - Heated</Option>
                                    <Option value="Yellow Sapphire">Yellow Sapphire</Option>
                                    <Option value="Pink Sapphire Natural">Pink Sapphire - Natural</Option>
                                    <Option value="Pink Sapphire Treated">Pink Sapphire - Treated</Option>
                                    <Option value="Purple Sapphire Natural">Purple Sapphire - Natural</Option>
                                    <Option value="Violet Sapphire Natural">Violet Sapphire - Natural</Option>
                                    <Option value="Blue Sapphire Treated Lots">Blue Sapphire - Treated Lots</Option>
                                    <Option value="Padparadscha Sapphire Natural">Padparadscha Sapphire -
                                        Natural</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name={`NEW_REF_${index + 1}`} label={`New Reference ${index + 1}`}
                                       initialValue={referenceData.NEW_REF}>
                                <Select
                                    placeholder="Select Reference"
                                    allowClear
                                    style={disableStyle}
                                >
                                    {referenceOptions.map((option) => (
                                        <Option key={option.value} value={option.value} title={option.label}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name={`WEIGHT_AFTER_CP_${index + 1}`} label="Weight CP" initialValue={referenceData.WEIGHT_AFTER_CP}>
                                <InputNumber min={0} step={0.01} placeholder="Enter Weight" style={ type === 'view' ? disableStyle : {width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name={`CP_COLOR_${index + 1}`}
                                       label="Color"
                                        initialValue={referenceData.CP_COLOR}
                            >
                                <Input  placeholder="Enter Color" style={ type === 'view' || type === 'cp_user_edit' ? disableStyle : {width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item name={`SHAPE_${index + 1}`} label="Shape" initialValue={referenceData.SHAPE}>
                                <Select placeholder="Select Shape" allowClear showSearch style={ type === 'view' ? disableStyle : {width: '100%'}}>
                                    <Option value="Oval">Oval</Option>
                                    <Option value="Cabochon Oval">Cabochon Oval</Option>
                                    <Option value="Cushion">Cushion</Option>
                                    <Option value="Heart">Heart</Option>
                                    <Option value="Pear">Pear</Option>
                                    <Option value="Radiant">Radiant</Option>
                                    <Option value="Rectangle">Rectangle</Option>
                                    <Option value="Round">Round</Option>
                                    <Option value="Square">Square</Option>
                                    <Option value="Sugarloaf">Sugarloaf</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {type !== 'cp_user_edit' ? (
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name={`TOTAL_COST_${index + 1}`}
                                label={`Total Cost To Item ${index + 1}`}
                                type="number"
                                initialValue={referenceData.TOTAL_COST}
                            >
                                <InputNumber min={0} step={0.01} placeholder="Enter Total Cost" style={ type === 'view' ? disableStyle : {width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        ) : null}
                        {referenceData.PHOTO ? (
                            <Col span={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Item label="Photo">
                                    <img
                                        alt="Initial Photo"
                                        style={{ width: '50%', borderRadius: '5px', cursor: 'pointer' }}
                                        key={`img${index}`}
                                        src={referenceData.PHOTO}
                                        onClick={() => {
                                            this.setState({ enlargedImageVisibleHT: index }); // Use index as the unique key
                                        }}
                                    />

                                    {/* Enlarged view modal */}
                                    <Modal
                                        visible={this.state.enlargedImageVisibleHT === index}
                                        footer={null}
                                        onCancel={() => this.setState({ enlargedImageVisibleHT: null })} // Reset to null when modal is closed
                                        key={`modal${index}`} // Use index as the unique key for the modal
                                    >
                                        <img
                                            alt="Enlarged View"
                                            style={{ width: '100%' }}
                                            key={`img${index}`}
                                            src={referenceData.PHOTO}
                                            onError={(e) => {
                                                console.error('Image loading error:', e);
                                            }}
                                        />
                                    </Modal>
                                </Form.Item>
                            </Col>

                        ) : null}
                        {type === 'edit' ? (
                        <Col xs={24} sm={24} md={24} lg={3}>
                            {/* File Upload */}
                            <Form.Item
                                name={`PHOTO_${index + 1}`}
                                label={`Upload Photo ${index + 1}`}
                            >
                                <Upload
                                    customRequest={({ onSuccess, onError, file }) => {
                                        onSuccess();
                                    }}
                                    fileList={this.state.fileList[index] || []}
                                    onChange={(info) => this.handleFileChange(info, { index })}
                                    {...fileLimit}
                                    listType="picture-card"
                                    showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
                                    maxCount={1} // Allow only one file
                                    onPreview={() => this.setState({ [`previewVisible${index}`]: true })}
                                >
                                    {this.state.fileList[index]?.length >= 1 ? null : (
                                        <div>
                                            <UploadOutlined />
                                            <div className="ant-upload-text">Upload</div>
                                        </div>
                                    )}
                                </Upload>
                                {/* Display uploaded image */}
                                <div className="clearfix">
                                    <Modal
                                        visible={this.state[`previewVisible${index}`]}
                                        footer={null}
                                        onCancel={() => this.setState({ [`previewVisible${index}`]: false })}
                                    >
                                        {this.state[`imgBBLink${index}`] === '' ? (
                                            <div className="loading-indicator">Uploading...</div>
                                        ) : (
                                            <img
                                                alt="Preview"
                                                style={{ width: '100%' }}
                                                src={this.state[`imgBBLink${index}`]}
                                                onError={(e) => {
                                                    console.error('Image loading error:', e);
                                                }}
                                            />
                                        )}
                                    </Modal>
                                </div>
                            </Form.Item>
                        </Col>
                        ) : null}
                    </Row>
                </div>
            ));
        }

    }





    render() {
        const { referenceOptions,customerOptions,fileList2,referenceGroupOptions } = this.state;

        const inputStyle = {
            pointerEvents: "none", // Disable pointer events to prevent interaction
            background: "#FFFFFF", // Set a background color to indicate it's disabled
            width: "100%"
        }

        const fileLimit = {
            accept: 'image/*', // Accept only image files
        };

        const type = this.props.type;

        return (
            <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                <Row gutter={[16, 16]} justify="left" align="top">
                    {this.props.refe === undefined ? (
                        <Col xs={24} sm={24} md={24} lg={6}>
                            <Form.Item
                                name="IS_USE_REFERENCE_GROUP"
                                label="Use CP Group as Reference"
                                initialValue={this.props.initialValues.IS_GROUP}
                            >
                                <Switch
                                    checkedChildren="Use Reference Group"
                                    unCheckedChildren="Use Reference Item"
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                    ) : null}
                    <Col xs={24} sm={12} md={12} lg={6}>
                        {/* Date */}
                        <Form.Item
                            name="DATE"
                            label="Date"
                            rules={[{ required: true, message: 'Please select Date' }]}  // Set the default date to today
                            initialValue={moment(this.props.initialValues.DATE)}
                        >
                            <DatePicker style = { type === 'view' || type === 'cp_user_edit' ? inputStyle : {width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6}>
                        <Form.Item name="CP_BY" label="Cutting & Polished By" rules={[{ required: true, message: 'Please select Cutting & Polished By' }]} initialValue={this.props.initialValues.CP_BY}>
                            <Select placeholder="Select Customer" allowClear showSearch style={ type === 'view' || type === 'cp_user_edit' ? inputStyle : {width: '100%'}}
                                    filterOption={(input, option) =>
                                        (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                        (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                    }>
                                {this.state.cpByOptions.map((option) => (
                                    <Option key={option.value} value={option.value} title={option.label}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {this.props.initialValues.IS_GROUP === 1 ? (
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item
                                name="REFERENCE_ID_CP_GROUP"
                                label="Reference Group"
                                rules={[{ required: true, message: 'Please select Reference Group' }]}
                                initialValue={this.props.initialValues.OLD_REFERENCE}
                            >
                                <Select
                                    placeholder="Select Reference Group"
                                    allowClear
                                    showSearch
                                    style={inputStyle}
                                    filterOption={(input, option) =>
                                        (option.key ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false) ||
                                        (option.title ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
                                    }
                                    onChange={this.loadReferenceItems}
                                >
                                    {referenceGroupOptions.map((option) => (
                                        <Option key={option.value} value={option.value} title={option.label}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : null}

                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="REMARK"
                            label="Remarks"
                            initialValue={this.props.initialValues.REMARK}
                        >
                            <Input.TextArea rows={2} placeholder="Enter remarks" style={ type === 'view' ? inputStyle : {width: '100%'}}/>
                        </Form.Item>
                    </Col>

                </Row>
                {this.renderFormFields()}
                {type !== 'view' ? (
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Update Cut and Polish
                            </Button>
                        </Form.Item>
                    </Col>
                    {type !== 'cp_user_edit' ? (
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Form.Item>
                            <Button type="default" style={{float: 'right'}} onClick={this.handleApprove}>
                                Approve
                            </Button>
                        </Form.Item>

                    </Col>
                    ) :
                        type === 'cp_user_edit' && this.props.initialValues.IS_FINISHED_BY_CP !== 1 ?
                        (
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Form.Item>
                                    <Button type="default" style={{float: 'right'}} onClick={this.handleMarkAsFinished}>
                                        Mark as Finished
                                    </Button>
                                </Form.Item>

                            </Col>
                        )
                        : null}
                </Row>
                ) : null}
            </Form>
        );
    }
}

export default UpdateCutPolish;
